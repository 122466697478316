import styled from 'styled-components'
import LAYOUTBG from '@/assets/background.png'

export const LayoutWrapper = styled.div`
  background: url(${LAYOUTBG});
  background-size: cover;
  background-position: center;
`

export const LayoutContent = styled.div`
  min-height: calc(100vh - 7.5rem);
  padding-top: 5rem;
`

export const LayoutTopBar = styled.div`
  position: fixed;
  z-index: 10;
  width: 100%;
`
