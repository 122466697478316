export const UserSvg = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.6484 3.77341C11.8594 3.93747 9.5625 6.21091 9.375 8.99997C9.16406 12.2812 11.7422 15.0234 14.9766 15.0234C18.0938 15.0234 20.6016 12.4922 20.6016 9.39841C20.625 6.16404 17.9062 3.58591 14.6484 3.77341ZM15 16.8515C11.25 16.8515 3.77344 18.7265 3.77344 22.4765V25.289C3.77344 25.8047 4.19531 26.2265 4.71094 26.2265H25.2891C25.8047 26.2265 26.2266 25.8047 26.2266 25.289V22.4531C26.2266 18.7265 18.75 16.8515 15 16.8515Z"
      fill="white"
    />
  </svg>
)

export const LogoutSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.7891 13.6722H20.7819L20.7111 13.6049V11.6088H11.1298V10.1286H20.7111V8.06962L23.546 10.7683V10.7761L20.7891 13.6722ZM15.7141 1.41746H3.93898L8.45537 3.65436C9.05568 3.95168 9.54329 4.68074 9.54329 5.28227L9.6141 18.5532C9.66585 18.5412 9.7197 18.5346 9.77513 18.5346H15.7321V13.8606H17.1495V19.2434C17.1495 19.6348 16.8322 19.9521 16.4408 19.9521H9.77513C9.72233 19.9521 9.67096 19.9462 9.62147 19.9352L9.64198 23.78C9.64198 23.9817 9.47881 24.0587 9.27766 23.9519L1.33666 19.5465C0.848554 19.2874 0.454114 18.6758 0.454868 18.1802L0.48101 0.902474C0.463534 0.840868 0.453979 0.775934 0.453979 0.70873C0.453979 0.632107 0.466288 0.558399 0.488779 0.489284C0.523857 0.226049 0.687044 0.0668898 0.91722 0.043717C0.993718 0.0154665 1.0764 0 1.16271 0H16.4228C16.8143 0 17.1316 0.317305 17.1316 0.70873C17.1316 0.757911 17.1265 0.805916 17.117 0.852271C17.1265 0.898625 17.1316 0.94663 17.1316 0.995811V7.78706H15.7141V1.41746Z"
      fill="white"
    />
  </svg>
)
