import Web3 from 'web3'
import { MerkleTree } from 'merkletreejs'

type Types = {
  addr: string
  web3: Web3
  whiteList: string[]
}

export const getProofForAddress = ({ addr, web3, whiteList }: Types): string[] => {
  const mekleTreeLeaves = whiteList.map((address) => web3.utils.keccak256(address))

  const mekleTree = new MerkleTree(mekleTreeLeaves, web3.utils.keccak256, {
    sortPairs: true,
  })

  try {
    const leaf = web3.utils.keccak256(addr)
    const proof = mekleTree.getHexProof(leaf)
    return proof
  } catch (err) {
    console.log(err)
    return []
  }
}

const checkTree = ({ web3, pairs, root, whiteList }: { pairs: string[]; web3: Web3; root: string; whiteList: string[] }) => {
  const mekleTreeLeaves = whiteList.map((address) => web3.utils.keccak256(address))

  const mekleTree = new MerkleTree(mekleTreeLeaves, web3.utils.keccak256, {
    sortPairs: true,
  })
  // const mekleTreeRoot = mekleTree.getRoot().toString('hex')
  // eslint-disable-next-line
  for (const [key, value] of Object.entries(pairs)) {
    const leafs_proof = getProofForAddress({ addr: value, web3, whiteList })
    const mekle_leaf = web3.utils.keccak256(value)

    if (!mekleTree.verify(leafs_proof, mekle_leaf, root)) {
      // console.error('Verification failed')
      return false
    }
  }
  return true
}

export const verify = async ({ arr, web3, root, whiteList }: { arr: string[]; web3: Web3; root: string; whiteList: string[] }) => {
  if (checkTree({ pairs: arr, web3, root, whiteList })) return await true
  else return await false
}
