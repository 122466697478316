import styled, { css } from 'styled-components'
import { Row } from 'antd'

export const ModalContentRow = styled(Row)<{ isTrue: boolean }>`
  ${({ isTrue }) =>
    !isTrue &&
    css`
      justify-content: center !important;
    `}
`

export const webLayoutAdaptationMax = css`
  max-width: min(68.75rem, 120rem - 45rem);
  margin: 0 auto;
`

export const webLayoutAdaptation = css`
  max-width: min(57.3%, 100% - 42.7%);
  margin: 0 auto;
`

export const flatLayoutAdaptation = css`
  max-width: min(75.01%, 100% - 24.99%);
  margin: 0 auto;
`

export const h5LayoutAdaptation = css`
  max-width: min(96.3%, 100% - 6.4%);
  margin: 0 auto;
`

export const Wrappers = css`
  ${h5LayoutAdaptation}
  min-height: calc(100vh - 2.75rem - 6.25rem);
  ${(props) =>
    props.theme.mediaWidth.md(
      () => css`
        ${flatLayoutAdaptation}
        min-height: calc(100vh - 5rem);
      `,
    )}
  ${(props) =>
    props.theme.mediaWidth.mxl(
      () => css`
        ${webLayoutAdaptation}
      `,
    )}
  ${(props) =>
    props.theme.mediaWidth.maxl(
      () => css`
        ${webLayoutAdaptationMax}
      `,
    )}
`

export const WrapperOthers = css`
  ${h5LayoutAdaptation}
  min-height: calc(100vh - 2.75rem - 6.25rem);
  ${(props) =>
    props.theme.mediaWidth.md(
      () => css`
        ${flatLayoutAdaptation}
        padding: 0 5.2%;
        min-height: calc(100vh - 5rem);
      `,
    )}
  ${(p) =>
    p.theme.mediaWidth.lg(
      () => css`
        padding: 0 10.4%;
      `,
    )}
  ${(props) =>
    props.theme.mediaWidth.mxl(
      () => css`
        ${webLayoutAdaptation}
      `,
    )}
  ${(props) =>
    props.theme.mediaWidth.maxl(
      () => css`
        ${webLayoutAdaptationMax}
      `,
    )}
`

export const HomeBtnCss = css`
  background: ${({ theme }) => theme.themeColor};
  border-radius: 0.5rem;
  min-width: 21.375rem;
  height: 4rem;
  text-shadow: none;
  box-shadow: none;
  border: none;
  font-family: 'NotoSansHans-Regular';
  font-weight: 700;
  color: #0b0910;
  font-size: 1rem;
  text-align: center;
  &:hover,
  &:focus {
    border-color: transparent;
    color: #0b0910;
    background: ${({ theme }) => theme.themeColor};
  }
  ${({ theme }) =>
    theme.mediaWidth.md(
      () => css`
        min-width: 21.38rem;
        height: 4rem;
        font-size: 1.25rem;
      `,
    )}
`
