import Web3 from 'web3'
import { StarshipNFT_ABI, USECONSTANT } from '@/contracts/constant'
import { REACT_APP_ENV } from '@/contracts/chains'
import BigNumber from 'bignumber.js'

export interface ConstantTypes {
  ContractStarshipNFT: any
}

export interface ConstantInitTypes {
  web3: Web3
  StarshipNFT_ADDRESS: string
  constant: ConstantTypes
  toWeiPowBanlance: ({ decimals, balance }: { decimals: string; balance: string }) => string
  fromWeiPowBanlance: ({ decimals, balance }: { decimals: string; balance: string }) => string
  apiUrl: string
  apiKey: string
}

export class ConstantInit {
  web3: Web3
  StarshipNFT_ADDRESS: string
  constant: ConstantTypes
  apiUrl: string
  apiKey: string

  constructor(provider: any, chainId: number) {
    const { StarshipNFT_ADDRESS, apiKey, apiUrl } = USECONSTANT[chainId]

    this.web3 = new Web3(provider)
    this.StarshipNFT_ADDRESS = StarshipNFT_ADDRESS
    this.apiKey = apiKey
    this.apiUrl = apiUrl
    this.constant = {
      ContractStarshipNFT: new this.web3.eth.Contract(StarshipNFT_ABI, StarshipNFT_ADDRESS),
    }

    console.log('REACT_APP_ENV', REACT_APP_ENV)
  }

  fromWeiPowBanlance = ({ decimals, balance }: { decimals: string; balance: string }) => {
    let wei = new BigNumber(10).pow(decimals)
    let balances = new BigNumber(balance).div(wei).toFixed(6)
    return balances
  }

  toWeiPowBanlance = ({ decimals, balance }: { decimals: string; balance: string }) => {
    let wei = new BigNumber(10).pow(decimals)
    let balances = new BigNumber(balance).times(wei).toFixed(0)
    return balances
  }
}
