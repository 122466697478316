import styled, { css } from 'styled-components'
import { Button, Spin } from 'antd'
import { h5LayoutAdaptation, flatLayoutAdaptation, webLayoutAdaptation, webLayoutAdaptationMax, HomeBtnCss } from '@/common/styled'

export const HomeWrapper = styled.div`
  ${h5LayoutAdaptation}
  width: 100%;
  min-height: calc(100vh - 5rem);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${(props) =>
    props.theme.mediaWidth.md(
      () => css`
        ${flatLayoutAdaptation}
      `,
    )}
  ${(props) =>
    props.theme.mediaWidth.mxl(
      () => css`
        ${webLayoutAdaptation}
      `,
    )}
  ${(props) =>
    props.theme.mediaWidth.maxl(
      () => css`
        ${webLayoutAdaptationMax}
      `,
    )}
`

export const HomeLoading = styled(Spin)`
  color: ${({ theme }) => theme.white};
  .ant-spin-dot-item {
    background-color: ${({ theme }) => theme.white};
  }
`

export const HomeContent = styled.div`
  position: relative;
  bottom: -5.9375rem;
`

export const HomeContentVerifyTrue = styled.div`
  position: relative;
  top: -5.9375rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const MintTip = styled.div`
  font-family: 'NotoSansHans-Regular';
  font-weight: 400;
  font-size: 1rem;
  text-align: center;
  position: relative;
  color: ${(p) => p.theme.white};

  &::before {
    content: '';
    position: absolute;
    left: calc(-0.38rem - 2.63rem);
  }
  &::after,
  &::before {
    content: '';
    position: absolute;
    top: calc(50% - 0.06rem);
    right: calc(-0.38rem - 2.63rem);
    width: 2.63rem;
    border-top: 0.13rem solid ${({ theme }) => theme.white};
  }
  ${({ theme }) =>
    theme.mediaWidth.md(
      () => css`
        font-size: 1.25rem;
        &::before {
          content: '';
          left: calc(-0.38rem - 5.63rem);
        }
        &::after,
        &::before {
          content: '';
          right: calc(-0.38rem - 5.63rem);
          width: 5.63rem;
        }
      `,
    )}
`

export const HomeMintBtn = styled(Button)`
  ${HomeBtnCss}
`

export const HomeOverDetails = styled.div`
  width: 100%;
  min-height: 46.63rem;
  background: ${({ theme }) => theme.white};
  border-radius: 0.5rem;
  padding: 0.94rem 1.62rem;
  margin-bottom: 1.25rem;
  .details-top {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.25rem;
    margin-bottom: 1.25rem;
    .ant-image-img {
      filter: drop-shadow(0rem 0.13rem 0.5rem rgba(0, 0, 0, 0.2));
      border-radius: 0.5rem;
    }
  }
  .details-content {
    p {
      font-family: 'NotoSansHans-Regular';
      font-weight: 400;
      font-size: 0.88rem;
      line-height: 1.88rem;
      color: ${({ theme }) => theme.black};
      text-indent: 2em;
      margin-bottom: 0;
    }
  }
  ${({ theme }) =>
    theme.mediaWidth.md(
      () => css`
        max-height: 80vh;
        padding: 2.19rem 4.06rem;

        .details-top {
          display: grid;
          grid-template-columns: 2fr 3fr;
          gap: 1.25rem;
          margin-bottom: 2.5rem;
        }
      `,
    )}
`

export const HomeRightContent = styled.div`
  display: flex;
  flex-direction: column;
  p {
    font-family: 'NotoSansHans-Regular';
    font-weight: 400;
    font-size: 0.88rem;
    line-height: 1.88rem;
    color: ${({ theme }) => theme.black};
    background: #eeeeee;
    padding: 0.19rem 0.63rem;
    border-radius: 0.5rem;
    span {
      margin-right: 0.63rem;
      font-weight: 700;
    }
  }
`

export const HomeDescriptionTitle = styled.div`
  font-family: 'NotoSansHans-Regular';
  font-weight: 700;
  font-size: 1rem;
  color: ${({ theme }) => theme.black};
  margin-bottom: 0.94rem;
  ${({ theme }) =>
    theme.mediaWidth.md(
      () => css`
        font-size: 1.25rem;
        margin-bottom: 1.25rem;
      `,
    )}
`

export const HomeTitle = styled.div`
  font-family: 'NotoSansHans-Regular';
  font-weight: 400;
  font-size: 4rem;
  line-height: 5rem;
  color: ${({ theme }) => theme.white};
  ${({ theme }) =>
    theme.mediaWidth.md(
      () => css`
        font-size: 8rem;
        line-height: 12rem;
      `,
    )}
`

export const HomeSubtitle = styled.div`
  margin-bottom: 3.75rem;
  margin-top: 1em;
  p {
    text-align: center;
    font-family: 'NotoSansHans-Regular';
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.875rem;
    color: ${({ theme }) => theme.white};
  }
  ${({ theme }) =>
    theme.mediaWidth.md(
      () => css`
        margin-top: 0;
        margin-bottom: 5rem;
        p {
          font-size: 1.25rem;
          line-height: 1.875rem;
          margin-bottom: 1em;
        }
      `,
    )}
`
