import footer from './en-us/footer'
import home from './en-us/home'
import wallet from './en-us/wallet'

const en = {
  'app.link.btn': 'Wallet Connect',
  'app.no.chainid.tips': 'Please switch your wallet to a supported network',
  'app.no.chainid.btn': 'Change Network',
  'app.chainid.drawer.title': 'Select network',
  'app.switch.language.tips': 'Switch {{msg}} Success',
  'app.404.title': 'The requested URL was not found',
  'app.404.btn': 'Back to Homepage',
  'app.loading': 'Loading...',
  ...footer,
  ...home,
  ...wallet,
}

export default en
