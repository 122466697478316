import styled from 'styled-components'
import { h5LayoutAdaptation } from '@/common/styled'

export const TopBarWrapper = styled.div`
  ${h5LayoutAdaptation}
  height: 5rem;
  .topbar-ant-row {
    width: 100%;
    height: 5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    .topbar-right {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
    }
  }
`
